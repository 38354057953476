
@font-face {
	font-family: 'Montserrat';
	src: url('./fonts/Montserrat-Light.eot');
	src: local('Montserrat Light'), local('Montserrat-Light'),
		url('./fonts/Montserrat-Light.eot') format('embedded-opentype'),
		url('./fonts/Montserrat-Light.woff') format('woff'),
		url('./fonts/Montserrat-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('./fonts/Montserrat-MediumItalic.eot');
	src: local('Montserrat Medium Italic'), local('Montserrat-MediumItalic'),
		url('./fonts/Montserrat-MediumItalic.eot') format('embedded-opentype'),
		url('./fonts/Montserrat-MediumItalic.woff') format('woff'),
		url('./fonts/Montserrat-MediumItalic.ttf') format('truetype');
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: 'Montserrat';
	src: url('./fonts/Montserrat-Thin.eot');
	src: local('Montserrat Thin'), local('Montserrat-Thin'),
		url('./fonts/Montserrat-Thin.eot') format('embedded-opentype'),
		url('./fonts/Montserrat-Thin.woff') format('woff'),
		url('./fonts/Montserrat-Thin.ttf') format('truetype');
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('./fonts/Montserrat-ExtraLightItalic.eot');
	src: local('Montserrat ExtraLight Italic'), local('Montserrat-ExtraLightItalic'),
		url('./fonts/Montserrat-ExtraLightItalic.eot') format('embedded-opentype'),
		url('./fonts/Montserrat-ExtraLightItalic.woff') format('woff'),
		url('./fonts/Montserrat-ExtraLightItalic.ttf') format('truetype');
	font-weight: 200;
	font-style: italic;
}

@font-face {
	font-family: 'Montserrat';
	src: url('./fonts/Montserrat-BoldItalic.eot');
	src: local('Montserrat Bold Italic'), local('Montserrat-BoldItalic'),
		url('./fonts/Montserrat-BoldItalic.eot') format('embedded-opentype'),
		url('./fonts/Montserrat-BoldItalic.woff') format('woff'),
		url('./fonts/Montserrat-BoldItalic.ttf') format('truetype');
	font-weight: bold;
	font-style: italic;
}

@font-face {
	font-family: 'Montserrat';
	src: url('./fonts/Montserrat-SemiBold.eot');
	src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'),
		url('./fonts/Montserrat-SemiBold.eot') format('embedded-opentype'),
		url('./fonts/Montserrat-SemiBold.woff') format('woff'),
		url('./fonts/Montserrat-SemiBold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('./fonts/Montserrat-ExtraLight.eot');
	src: local('Montserrat ExtraLight'), local('Montserrat-ExtraLight'),
		url('./fonts/Montserrat-ExtraLight.eot') format('embedded-opentype'),
		url('./fonts/Montserrat-ExtraLight.woff') format('woff'),
		url('./fonts/Montserrat-ExtraLight.ttf') format('truetype');
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('./fonts/Montserrat-ExtraBoldItalic.eot');
	src: local('Montserrat ExtraBold Italic'), local('Montserrat-ExtraBoldItalic'),
		url('./fonts/Montserrat-ExtraBoldItalic.eot') format('embedded-opentype'),
		url('./fonts/Montserrat-ExtraBoldItalic.woff') format('woff'),
		url('./fonts/Montserrat-ExtraBoldItalic.ttf') format('truetype');
	font-weight: 800;
	font-style: italic;
}

@font-face {
	font-family: 'Montserrat';
	src: url('./fonts/Montserrat-Italic.eot');
	src: local('Montserrat Italic'), local('Montserrat-Italic'),
		url('./fonts/Montserrat-Italic.eot') format('embedded-opentype'),
		url('./fonts/Montserrat-Italic.woff') format('woff'),
		url('./fonts/Montserrat-Italic.ttf') format('truetype');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'Montserrat';
	src: url('./fonts/Montserrat-Bold.eot');
	src: local('Montserrat Bold'), local('Montserrat-Bold'),
		url('./fonts/Montserrat-Bold.eot') format('embedded-opentype'),
		url('./fonts/Montserrat-Bold.woff') format('woff'),
		url('./fonts/Montserrat-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('./fonts/Montserrat-LightItalic.eot');
	src: local('Montserrat Light Italic'), local('Montserrat-LightItalic'),
		url('./fonts/Montserrat-LightItalic.eot') format('embedded-opentype'),
		url('./fonts/Montserrat-LightItalic.woff') format('woff'),
		url('./fonts/Montserrat-LightItalic.ttf') format('truetype');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'Montserrat';
	src: url('./fonts/Montserrat-BlackItalic.eot');
	src: local('Montserrat Black Italic'), local('Montserrat-BlackItalic'),
		url('./fonts/Montserrat-BlackItalic.eot') format('embedded-opentype'),
		url('./fonts/Montserrat-BlackItalic.woff') format('woff'),
		url('./fonts/Montserrat-BlackItalic.ttf') format('truetype');
	font-weight: 900;
	font-style: italic;
}

@font-face {
	font-family: 'Montserrat';
	src: url('./fonts/Montserrat-SemiBoldItalic.eot');
	src: local('Montserrat SemiBold Italic'), local('Montserrat-SemiBoldItalic'),
		url('./fonts/Montserrat-SemiBoldItalic.eot') format('embedded-opentype'),
		url('./fonts/Montserrat-SemiBoldItalic.woff') format('woff'),
		url('./fonts/Montserrat-SemiBoldItalic.ttf') format('truetype');
	font-weight: 600;
	font-style: italic;
}

@font-face {
	font-family: 'Montserrat';
	src: url('./fonts/Montserrat-Regular.eot');
	src: local('Montserrat Regular'), local('Montserrat-Regular'),
		url('./fonts/Montserrat-Regular.eot') format('embedded-opentype'),
		url('./fonts/Montserrat-Regular.woff') format('woff'),
		url('./fonts/Montserrat-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('./fonts/Montserrat-Medium.eot');
	src: local('Montserrat Medium'), local('Montserrat-Medium'),
		url('./fonts/Montserrat-Medium.eot') format('embedded-opentype'),
		url('./fonts/Montserrat-Medium.woff') format('woff'),
		url('./fonts/Montserrat-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('./fonts/Montserrat-ExtraBold.eot');
	src: local('Montserrat ExtraBold'), local('Montserrat-ExtraBold'),
		url('./fonts/Montserrat-ExtraBold.eot') format('embedded-opentype'),
		url('./fonts/Montserrat-ExtraBold.woff') format('woff'),
		url('./fonts/Montserrat-ExtraBold.ttf') format('truetype');
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('./fonts/Montserrat-Black.eot');
	src: local('Montserrat Black'), local('Montserrat-Black'),
		url('./fonts/Montserrat-Black.eot') format('embedded-opentype'),
		url('./fonts/Montserrat-Black.woff') format('woff'),
		url('./fonts/Montserrat-Black.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('./fonts/Montserrat-ThinItalic.eot');
	src: local('Montserrat Thin Italic'), local('Montserrat-ThinItalic'),
		url('./fonts/Montserrat-ThinItalic.eot') format('embedded-opentype'),
		url('./fonts/Montserrat-ThinItalic.woff') format('woff'),
		url('./fonts/Montserrat-ThinItalic.ttf') format('truetype');
	font-weight: 100;
	font-style: italic;
}

* {
  font-family: 'Montserrat' !important;
}

html, body {
    height: 100%;
    width: 100%;
    background-color: #ffffff;
    margin: 0;
    box-sizing: border-box;
    -webkit-overflow-scrolling: touch;
    font-family: 'Montserrat';
}


html {
    position: absolute;
    top: 0;
    left: 0;
    padding: 0;
    height: auto;
}

.candidateDetailsPage a, .candidateDetailsPage button, .candidateDetailsPage input, .candidateDetailsPage select,
#whole-app a, #whole-app button, #whole-app input, #whole-app select {
    font-family: 'Montserrat'!important;
}

body {
    /*padding: 1rem;*/
    overflow: auto;
    font-size: 18px;
}

